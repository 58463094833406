<template>
  <div>
    <div class="page-header panel">
      <h3 class="title">Үйлчилгээ</h3>
      <div class="action-section">
        <router-link to="/add-service">
          <el-button type="success" size="mini" icon="el-icon-plus"
            >Нэмэх</el-button
          >
        </router-link>
      </div>
    </div>
    <el-row :gutter="0">
      <el-col :span="24" :offset="0">
        <div class="panel payments-container">
          <div align="right">
            <el-input
              style="width: 200px"
              prefix-icon="el-icon-search"
              v-model="search"
              size="mini"
              placeholder="Хайх үйлчилгээний нэр"
            />
          </div>
          <el-table
            :data="
              services.filter(
                data =>
                  !search ||
                  data.name_mon.toLowerCase().includes(search.toLowerCase())
              )
            "
          >
            <el-table-column prop="image" label="Зураг" width="150px">
              <template slot-scope="scope">
                <el-image :src="scope.row.image" style="width: 30px"></el-image>
              </template>
            </el-table-column>
            <el-table-column
              prop="name_mon"
              label="Монгол нэр"
            ></el-table-column>
            <el-table-column
              prop="name_eng"
              label="Англи нэр"
            ></el-table-column>
            <el-table-column
              prop="status"
              label="Төлөв"
              width="200"
              :filters="[
                { text: 'Идэвхтэй', value: 'active' },
                { text: 'Идэвхгүй', value: 'inactive' }
              ]"
              :filter-method="filterTag"
              filter-placement="bottom-end"
            >
              <template slot-scope="scope">
                <el-tag
                  size="mini"
                  effect="plain"
                  :type="scope.row.status === 'active' ? 'success' : 'info'"
                  disable-transitions
                  >{{
                    scope.row.status === "active" ? "Идэвхитэй" : "Идэвхигүй"
                  }}</el-tag
                >
              </template>
            </el-table-column>

            <el-table-column
              label="Үйлдэл"
              width="200px"
              v-if="mArrayIncludes(role, ['boss', 'ST'])"
            >
              <template slot-scope="scope">
                <el-button
                  type="primary"
                  size="mini"
                  icon="el-icon-edit"
                  @click="showEdit(scope.row)"
                  round
                ></el-button>
                <el-button
                  type="danger"
                  size="mini"
                  icon="el-icon-delete"
                  @click="deleteSer(scope.row.id)"
                  round
                ></el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-col>
    </el-row>
    <el-dialog
      :title="'Үйлчилгээний нэр : ' + serviceEditData.name_mon + ' '"
      :visible.sync="dialogVisible"
      @close="closeProgress()"
      @closed="closeProgress()"
      width="40%"
      top="50px"
      class="process-dialog"
    >
      <div class="panel">
        <el-form
          :model="serviceEditData"
          :rules="rules"
          ref="serviceEditData"
          label-width="220px"
          class="demo-ruleForm"
        >
          <el-form-item label="Нэр">
            <el-input
              style="width: 250px"
              size="mini"
              v-model="serviceEditData.name_mon"
              placeholder="Үйлчилгээний нэр оруулна уу"
            ></el-input>
          </el-form-item>
          <el-form-item label="Англи нэр">
            <el-input
              style="width: 250px"
              size="mini"
              v-model="serviceEditData.name_eng"
              placeholder="Үйлчилгээний англи нэр оруулна уу"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="Төрөл">
            <el-select v-model="serviceEditData.type">
              <el-option
                v-for="item in [
                  { label: 'Хуудас', value: 'defR' },
                  { label: 'Үсрэх', value: 'pop' },
                  { label: 'Линк', value: 'link' }
                ]"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            v-if="serviceEditData.type != 'link'"
            label="Үсрэх хуудас"
          >
            <el-select v-model="serviceEditData.route">
              <el-option
                v-for="item in [
                  { label: 'Харилцагч', value: 'RestaurantScreen' }
                ]"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="serviceEditData.type == 'link'" label="Линк">
            <el-input
              style="width: 250px"
              size="mini"
              v-model="serviceEditData.link"
              placeholder="Линк оруулна уу"
            >
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-switch
              v-model="serviceEditData.is_show"
              active-text="Харагдуулах"
              inactive-text="Нуух"
            >
            </el-switch>
          </el-form-item>
          <el-form-item label="Үйлчилгээний төлөв" prop="status">
            <el-radio
              v-model="serviceEditData.status"
              label="active"
              border
              size="mini"
              >Идэвхитэй</el-radio
            >
            <el-radio
              v-model="serviceEditData.status"
              label="inactive"
              border
              size="mini"
              >Идэвхгүй</el-radio
            >
          </el-form-item>
          <el-form-item label="Хүргэх хугацаа" prop="promised_time">
            <el-input
              style="width: 250px"
              size="mini"
              type="number"
              v-model="serviceEditData.promised_time"
            ></el-input>
          </el-form-item>
          <el-form-item label="Баннер хоорондох зай" prop="promised_time">
            <el-input-number
              size="mini"
              v-model="serviceEditData.bannerSkip"
              :min="1"
            ></el-input-number>
          </el-form-item>
          <el-form-item label="Эрэмбэ">
            <el-input
              style="width: 250px"
              size="mini"
              type="number"
              v-model="serviceEditData.sort"
            ></el-input>
          </el-form-item>
          <el-form-item label="Баджийн төрөл">
            <el-select
              v-model="serviceEditData.badgeType"
              placeholder="Баджийн төрөл сонгоно уу"
            >
              <el-option label="Зураг" value="image"></el-option>
              <el-option label="Текст" value="text"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            v-if="serviceEditData.badgeType === 'text'"
            label="Баджийн текст"
            prop="badge"
          >
            <el-input
              style="width: 250px"
              size="mini"
              v-model="serviceEditData.setBadgeText"
            ></el-input>
          </el-form-item>
          <el-form-item
            v-if="serviceEditData.badgeType === 'text'"
            label="Баджет өнгө"
          >
            <el-color-picker v-model="serviceEditData.badgeColor" />
          </el-form-item>
          <el-form-item
            label="Баджийн зураг"
            prop="badge"
            v-if="serviceEditData.badgeType === 'image'"
          >
            <el-input
              style="width: 250px"
              size="mini"
              placeholder="Баджийн зураг"
              v-model="serviceEditData.badgeImage"
            ></el-input>
          </el-form-item>
          <el-form-item
            v-if="serviceEditData.badgeType === 'image'"
            label="Баджийн зурагны байрлал"
            prop="badge"
          >
            <el-select
              v-model="serviceEditData.badgePosition"
              placeholder="Баджийн зурагны байрлал сонгоно уу"
            >
              <el-option label="Зүүн дээр" value="leftTop"></el-option>
              <el-option label="Зүүн доор" value="leftBottom"></el-option>
              <el-option label="Баруун дээр" value="rightTop"></el-option>
              <el-option label="Баруун доор" value="rightBottom"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="Англи баджет"
            v-if="serviceEditData.badgeType === 'text'"
          >
            <el-input
              style="width: 250px"
              size="mini"
              v-model="serviceEditData.setBadgeEng"
              placeholder="Англи баджет оруулна уу"
            ></el-input>
          </el-form-item>
          <el-form-item label="Зураг url" prop="image">
            <el-input
              style="width: 250px"
              size="mini"
              v-model="serviceEditData.image"
            ></el-input>
          </el-form-item>
          <el-form-item label="Тайлбар гарчиг">
            <el-input
              style="width: 250px"
              size="mini"
              v-model="serviceEditData.setMessageTitle"
            ></el-input>
          </el-form-item>
          <el-form-item label="Тайлбар дэлгэрэнгүй">
            <el-input
              style="width: 250px"
              size="mini"
              v-model="serviceEditData.setMessageDetail"
            ></el-input>
          </el-form-item>
          <el-form-item label="Тайлбар англи гарчиг">
            <el-input
              style="width: 250px"
              size="mini"
              v-model="serviceEditData.setMessageTitleEng"
            ></el-input>
          </el-form-item>
          <el-form-item label="Тайлбар англи дэлгэрэнгүй">
            <el-input
              style="width: 250px"
              size="mini"
              v-model="serviceEditData.setMessageDetailEng"
            ></el-input>
          </el-form-item>
          <el-form-item label="Pickup категори эсэх">
            <el-switch v-model="serviceEditData.is_pickup"></el-switch>
          </el-form-item>
          <el-form-item
            label="Pickup цэсэнд харагдах эрэмбэ"
            prop="pickup_sort"
          >
            <el-input-number
              :precision="2"
              :step="0.1"
              size="mini"
              v-model="serviceEditData.pickup_sort"
            ></el-input-number>
          </el-form-item>
          <el-form-item label="Шинж чанар нэмэх">
            <el-checkbox
              @change="isPropertyFunc(isProperty)"
              v-model="isProperty"
            >
              Шинж чанар нэмэх
            </el-checkbox>
          </el-form-item>
          <div v-if="isProperty === true" class="demo-input-size">
            <div class="text-right">
              <el-button
                @click="addPropertyRow()"
                size="mini"
                type="primary"
                circle
                >+</el-button
              >
            </div>
            <el-table
              :data="properties"
              style="width: 100%; padding-left:10%;"
              max-height="350"
            >
              <el-table-column prop="name" label="Нэр" width="150">
                <template slot-scope="scope">
                  <el-input
                    size="small"
                    placeholder="Порц"
                    style="text-align:center"
                    v-model="scope.row.name"
                  ></el-input>
                </template>
              </el-table-column>
              <el-table-column prop="value" label="Утга" width="150">
                <template slot-scope="scope">
                  <el-input
                    size="small"
                    placeholder="1-2хүн"
                    style="text-align:center"
                    v-model="scope.row.value"
                  ></el-input>
                </template>
              </el-table-column>
              <el-table-column label="Үйлдэл" width="100">
                <template slot-scope="scope">
                  <el-button
                    @click="deletePropertyRow(scope.$index)"
                    type="text"
                    size="mini"
                  >
                    Устгах
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <el-form-item>
            <el-button type="primary" @click="save()">Засварлах</el-button>
            <el-button :to="{ path: '/service' }" @click="closeProgress()"
              >Буцах</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import service from "@/helpers/services.js";
import { getGroups } from "../utils/auth";
import { arrayIncludes } from "../helpers/helper";
// import EditService from "@/components/EditService";
export default {
  mounted() {
    this.getServiceList();
    getGroups().then(data => {
      this.role = data;
    });
  },
  // components: { EditService },
  data() {
    return {
      dialogVisible: false,
      search: "",
      services: [],
      serviceEditData: {},
      sendEdit: false,
      sendDeleteItem: {
        id: 0
      },
      pageMessage: {
        title: "",
        message: ""
      },
      loadUpload: false,
      imageNull: "none",
      rules: {
        title: [
          {
            required: true,
            message: "Та зарлал нэрээ оруулна уу",
            trigger: "blur"
          },
          {
            min: 3,
            message: "Хамгийн багадаа 3 үсэг оруулна уу",
            trigger: "blur"
          }
        ]
      },
      role: [],
      properties: [],
      isProperty: false
    };
  },
  methods: {
    closeProgress() {
      this.serviceEditData = {};
      this.getServiceList();
      this.dialogVisible = false;
    },
    showEdit(data) {
      this.properties = data.properties;
      this.isProperty = data.is_property_added;
      this.serviceEditData = Object.assign({}, data);
      this.dialogVisible = true;
    },
    filterTag(value, row) {
      return row.status === value;
    },
    getServiceList() {
      service.getServices(false, "", "").then(res => {
        for (let i = 0; i < res.data.data.length; i++) {
          if (res.data.data[i].badge !== null) {
            res.data.data[i].badgeType = res.data.data[i].badge_type;
            res.data.data[i].setBadgeText = res.data.data[i].badge.title;
            res.data.data[i].setBadgeEng = res.data.data[i].badge.title_eng;
            res.data.data[i].badgeColor = res.data.data[i].badge.badge_color;
            res.data.data[i].badgeImage = res.data.data[i].badge.image;
            res.data.data[i].badgePosition = res.data.data[i].badge.position;
            if (res.data.data[i].message !== null) {
              res.data.data[i].setMessageTitle = res.data.data[i].message.title;
              res.data.data[i].setMessageDetail =
                res.data.data[i].message.message;
              res.data.data[i].setMessageTitleEng =
                res.data.data[i].message.title_eng;
              res.data.data[i].setMessageDetailEng =
                res.data.data[i].message.message_eng;
            }
          }
        }
        this.services = res.data.data;
      });
    },
    save() {
      this.$refs.serviceEditData.validate(valid => {
        if (valid) {
          const badge_type = this.serviceEditData.badgeType;
          let badge = this.serviceEditData.badge;
          if (badge_type !== "" || badge_type !== null) {
            if (badge_type === "image") {
              badge = {
                title: "",
                title_eng: "",
                badge_color: "",
                image: this.serviceEditData.badgeImage,
                position: this.serviceEditData.badgePosition
              };
            } else if (badge_type === "text") {
              badge = {
                title: this.serviceEditData.setBadgeText,
                title_eng: this.serviceEditData.setBadgeEng,
                badge_color: this.serviceEditData.badgeColor,
                image: "",
                position: ""
              };
            } else badge = {};
            this.serviceEditData.badge_type = badge_type;
          }
          const message = {
            title: this.serviceEditData.setMessageTitle,
            message: this.serviceEditData.setMessageDetail,
            title_eng: this.serviceEditData.setMessageTitleEng,
            message_eng: this.serviceEditData.setMessageDetailEng
          };
          if ((badge.title && badge.title_eng) || badge.image) {
            this.serviceEditData.badge = badge;
          } else {
            this.serviceEditData.badge = null;
          }
          if (this.type === "link") this.serviceEditData.route = "link";
          if (
            message.title &&
            message.message &&
            message.title_eng &&
            message.message_eng
          ) {
            this.serviceEditData.message = message;
          } else {
            this.serviceEditData.message = null;
          }
          this.serviceEditData.is_property_added = this.isProperty;
          this.serviceEditData.properties = this.properties;
          service
            .updateService(this.serviceEditData, this.serviceEditData.id)
            .then(response => {
              if (response.data.status === "success") {
                this.$notify({
                  title: "Амжилттай",
                  message: "Үйлчилгээний мэдээлэл засагдлаа",
                  type: "success"
                });
                this.closeProgress();
              } else if (response.data.status === "unsuccess") {
                this.$notify({
                  title: "Амжилтгүй",
                  message:
                    "Үйлчилгээний мэдээлэл засахад алдаа гарлаа " +
                    " " +
                    response.data.error.message,
                  type: "warning"
                });
              }
            });
        } else {
          this.$notify({
            title: "Анхааруулга",
            message: "Зарим талбарыг бөглөөгүй байна.",
            type: "warning"
          });
          return false;
        }
      });
    },
    deleteSer(id) {
      this.$confirm("Энэ категори устгахдаа итгэлтэй байна уу?", "Анхаар", {
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",
        type: "warning"
      })
        .then(() => {
          service.deleteService(id).then(res => {
            if (res.data.status === "success") {
              this.$notify({
                title: "Амжилттай",
                message: "Категорийн мэдээлэл устгагдлаа",
                type: "success"
              });
              this.getServiceList();
            } else if (res.data.status === "unsuccess") {
              this.$notify({
                title: "Амжилтгүй",
                message:
                  "Категорийн мэдээлэл устгахад алдаа гарлаа " +
                  " " +
                  res.error.message,
                type: "warning"
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Буцлаа"
          });
        });
    },
    mArrayIncludes(base_array, searchArray) {
      return arrayIncludes(base_array, searchArray);
    },
    addPropertyRow() {
      this.properties.push({ name: "", value: "" });
    },
    deletePropertyRow(index) {
      this.properties.splice(index, 1);
    },
    isPropertyFunc(value) {
      if (value === false) {
        this.properties = [];
      }
    }
  }
};
</script>
